import React from 'react';
import { Column, Columns, Container } from 'bloomer';
import { ContTYC } from './styles';
import { PieTitleSection, TitleSection, TitleSectionH1 } from '../../styles/GlobalStyles';
import {
  GlobalStyle,
} from '../../styles/GlobalStyles';

export const Terminos = () => {

  return (
    <ContTYC>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 8 }}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSectionH1 className={'animate__animated animate__fadeInUp'}>TÉRMINOS Y <span>CONDICIONES</span></TitleSectionH1>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Estos Términos y Condiciones de servicio (“Términos y Condiciones”) regulan el uso y acceso a los servicios digitales y remotos propiedad de Callmatik, S.A. de C.V. (“Callmatik” o “nosotros”)  que podrás encontrar en www.callmatik.com, así como en cualquier subdominio que Callmatik ponga a su disposición y sea informado, o a cualquier aplicación, funcionalidad, contenido, material u otros servicios en línea o remotos, incluyendo, proporcionados por nosotros (“Plataforma Callmatik"). Cuando nos referimos al usuario o usuarios, nos estaremos refiriendo a Usted (“Usuario” o “Usuarios” o “Usted”) o a su representada, es decir a la persona física o moral que contrate los servicios ofrecidos por Callmatik.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                Al acceder y registrarse en la Plataforma Callmatik, el Usuario acepta los Términos y Condiciones que se describen a continuación, nuestro Aviso de Privacidad, así como cualquier otro aviso legal adicional que publiquemos en relación con los servicios.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                El Usuario deberá leer estos Términos y Condiciones con atención, por lo que asegúrese de entenderlos antes de contratar el servicio que le ofrecemos. Le sugerimos almacenar estos Términos y Condiciones en una copia de seguridad de su dispositivo inteligente y/o computadora para futuras consultas. Le hacemos de su conocimiento que Callmatik tiene la facultad discrecional de modificar estos Términos y Condiciones de forma ocasional sin previo aviso posteriormente se le hará llegar dichos cambios vía correo electrónico o mediante aviso en el dominio de Callmatik.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                SI USTED NO ACEPTA ESTAS CONDICIONES, NO PODRÁ ACCEDER, NI UTILIZAR LA PLATAFORMA, POR LO QUE EL USO DE LA PLATAFORMA ESTÁ EXPRESAMENTE CONDICIONADA A LA ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                1.	CONSENTIMIENTO

                Al aceptar estos Términos y Condiciones, el Usuario también acepta las siguientes políticas aplicables y accesibles en la Plataforma Callmatik, que se incorporan como referencia a estos Términos y Condiciones, Aviso de Privacidad, Política de Cookies y cualquier otra política disponible oportunamente en la Plataforma Callmatik, así como en cualquier subdominio que Callmatik ponga a su disposición y sea informado.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                2.	ELEGIBILIDAD

                El Usuario garantiza que tiene la suficiente capacidad legal para aceptar y obligarse con los presentes Términos y Condiciones para hacer uso de la Plataforma Callmatik y registrarse, así como a obligarse o a su representada, por cualquier responsabilidad en la que pueda incurrir como resultado del uso de la Plataforma Callmatik.

                No se permitirá el registro si:

                1)	El Usuario es menor de edad o no cuenta con capacidad legal suficiente para obligarse;
                2)	 Si el Usuario o en su caso, sus beneficiarios controladores son publicados por parte del Servicio de Administración Tributaria en su página de Internet en alguno de los supuestos a los que se refiere el artículo Artículo 69-B Bis del Código Fiscal de la Federación y/o en algunas de las de las Listas y/o Resoluciones publicadas por el Servicio de Administración Tributaria en el portal de internet denominado Sistema del Portal de Prevención de Lavado de Dinero (“SPPLD”).
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                3.	REGISTRO Y CUENTA

                Para acceder a la Plataforma Callmatik, es necesario que proporcione cierta información para crear y mantener una cuenta en la Plataforma Callmatik. Como tal, es una condición para su uso de la Plataforma Callmatik que toda la información que proporcione sea correcta, actual y completa. El Usuario acepta que toda la información que proporciona se rige por nuestro Aviso de Privacidad y da su consentimiento a todas las acciones que tomemos con respecto a su información de acuerdo con el Aviso de Privacidad. Incluyendo la transferencia de sus Datos Personales a terceros con fines de publicidad o promoción de bienes y servicios.

                Para poder hacer uso de los servicios, el Usuario deberá registrarse en la Plataforma Callmatik, crear una contraseña la cual se considerará un elemento identificador y habilitador para acceder a los servicios. Callmatik validará los datos y verificará la autenticidad de todos los datos y documentos obtenidos del Usuario.

                Callmatik tiene la facultad discrecional a negar, restringir, suspender, cancelar o condicionar al Usuario el registro o acceso a la Plataforma Callmatik, total o parcialmente, de manera temporal o definitiva, en cualquier momento y sin necesidad de previo aviso, si el Usuario quebranta cualquiera de las estipulaciones de los términos establecidos, si comete cualquier otro acto contrario a las leyes aplicables vigentes, si no pudiera verificarse su identidad o sus facultades de representación.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                4.	VERACIDAD EN LA INFORMACIÓN

                Es obligación del Usuario proporcionar información precisa y veraz al momento de registrarse, por lo que acepta informar sin dilación sobre cualquier cambio en la información que haya suministrado en su registro, con la finalidad de poder garantizar que recibe las comunicaciones y que no se contactará por error con ningún tercero, completando los formatos que Callmatik ponga a su disposición para tales efectos, con los datos personales solicitados de conformidad con el respectivo Aviso de Privacidad.

                Si el Usuario proporciona cualquier información que resulte falsa, desactualizada, inexacta, incompleta o incorrecta, o viola los derechos de terceros, de privacidad o de propiedad de cualquier otra persona, o en caso de que Callmatik tenga razones para sospechar que dicha información no es correcta puede suspender o terminar anticipadamente su acceso y actividad y negarle cualquier acceso presente o futuro a la Plataforma Callmatik.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                5.	CONTRASEÑA

                El Usuario es responsable en todo momento de su contraseña de acceso y deberá determinar en la Plataforma Callmatik, las combinaciones alfanuméricas que será su contraseña. El Usuario podrá cambiar en cualquier momento dicha contraseña directamente a través de la Plataforma Callmatik.

                El Usuario no podrá transferir su cuenta a ninguna otra persona y no podrá utilizar la cuenta de otra persona en ningún momento. En los casos en los que, no obstante, haya autorizado o registrado a otra persona para que utilice su cuenta, o cuando haya actuado de forma negligente en la protección de su cuenta, tal y como se ha indicado anteriormente, el Usuario acepta que es plenamente responsable de:

                1)	los actos y omisiones de dicha persona que acceda a la Plataforma Callmatik a través de su cuenta,
                2)	el control del acceso y uso de la Plataforma Callmatik por parte de dicha persona,
                3)	el uso de información que sea obtenida a través de la Plataforma Callmatik;
                4)	las instrucciones que sean recibidas y modifiquen las características de los Servicios contratados; y
                5)	en general de las consecuencias de cualquier uso o mal uso por parte de terceros.

                A partir del momento en que el Usuario solicite la inhabilitación o cancelación de su contraseña de usuario deberá seguir el proceso de recuperación de contraseña en la Plataforma Callmatik para que Callmatik esté en posibilidad de generar un nuevo acceso a la Plataforma Callmatik y el Usuario pueda restablecer su contraseña.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                6.	SERVICIOS

                A través de la Plataforma Callmatik el usuario podrá acceder a los servicios de Atención telefónica personalizada y opcionalmente a gestión de agenda personalizado.

                El Usuario deberá configurar su número de teléfono con la finalidad que las llamadas que elija puedan ser atendidas a través de la Plataforma Callmatik. Los mensajes que deseen dejarle serán recibidos por una operadora y serán enviados vía correo electrónico, para lo cual se le asignará un número en espcifico (“DID”).

                Adicionalmente, si el Usuario contrata una suscripción con servicio de citas de agenda podrá registrar un evento en su calendario. Para la prestación del servicio el cliente deberá facilitar la información necesaria para el acceso a su agenda. La agenda debe ser compatible con la agenda de Google Calendar u otras aceptadas oficialmente por Callmatik en cada momento.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                7.	SUSCRIPCIÓN

                El Usuario podrá acceder a los servicios a través de la Plataforma Callmatik siempre y cuando cuente con una suscripción vigente y haya completado el registro indicado anteriormente.

                A través del sitio web www.callmatik.com o la Plataforma Callmatik, Usted será guiado a través de los pasos que necesita para crear una suscripción con nosotros, la cual le permitirá acceder a las funcionalidades de la Plataforma Callmatik (una “Suscripción”).

                Nuestro proceso de registro le permite revisar y modificar cualquier error antes de la creación de su Suscripción, así como el nivel de Suscripción que requiere de acuerdo con sus necesidades. Al momento de realizar un cambio de Suscripción, el DID quedará desvinculado del número del Usuario, por lo que deberá realizar el proceso de vinculación nuevamente.

                Le sugerimos leer con atención y revisar y ratificar sus datos incluidos los bancarios, posteriormente se le dirigirá a una pantalla o una ventana emergente conocida como Pop-Pup reconociendo que ha creado su Suscripción.

                La aceptación de su Suscripción queda confirmada con el pago de la primera cuota de esta (el monto puede variar dependiendo el tipo de Suscripción).

                Su Suscripción quedará activa una vez que se haya pagado la primera cuota de la suscripción.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                8.	PRECIOS.

                Todas las cuotas de Suscripción se encuentran señaladas en la Plataforma Callmatik así mismo se harán de su conocimiento en el momento en que usted acepte suscribirse y pague la Suscripción (“Cuotas de Suscripción”).

                Las Cuotas de Suscripción, incluyen el IVA. Se cobrará el IVA de conformidad con las tasas locales del IVA. Sin embargo, si la tasa del IVA sufre una modificación durante la vigencia de estos Términos y Condiciones, podremos realizar el ajuste del IVA a efecto que usted pueda erogar sobre las cuotas de suscripción.

                Los precios pueden sufrir modificaciones en cualquier momento a criterio único y exclusivo de Callmatik.

                El Usuario podrá modificar el tipo de Suscripción en cualquier momento. Comunicándolo con al menos 10 días de antelación a cada periodo de facturación mensual, ante cualquier modificación a la Suscripción nos reservamos el derecho de modificar el pago recurrente a su tarjeta de débito o crédito. También hacemos de su conocimiento que nos reservamos el derecho de alterar el monto del pago recurrente si el Usuario actúa con dolo o mala fe y se utilizan los servicios de manera distinta a los señalados en la Suscripción y no se está pagando por ellos.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                9.	PAGO.

                El Usuario puede pagar su Suscripción utilizando la versión de pagos recurrentes a tarjetas de débito o crédito. Tome en cuenta que utilizamos empresas y/o servicios especializados para llevar a cabo la operación conocidos como agregadores de pago, por lo que cualquier error al momento de realizar el pago quedará a cargo de dichos terceros.

                La primera cuota de Suscripción se cobrará al momento de establecer el método de pago que a usted le convenga esta será pagadera con respecto al primer mes de su Suscripción. Salvo los casos en que sea aplicable el periodo de prueba.

                Las cuotas de Suscripción se pagan de forma mensual. Por anticipado y vencen al mes de la fecha de pago de la primera cuota de Suscripción de manera automatizada.

                Si usted no llegase a pagar la cuota de Suscripción en la fecha de vencimiento del pago, le enviaremos un recordatorio automatizado de pago [Confirmar].

                Si usted no paga la cuota de Suscripción en un periodo de 15 dias a partir de la fecha en que debió hacer el pago (el “Vencimiento”) Callmatik tiene la facultad sin responsabilidad de cancelar el servicio por lo que bloquearemos su acceso a la Plataforma Callmatik.

                Si desea cambiar el número de usuarios que se pueden utilizar conforme a la Suscripción, nos reservamos el derecho de modificar de forma automatizada el pago recurrente con cargo a su tarjeta de débito o crédito. También hacemos de su conocimiento que nos reservamos el derecho de alterar el monto de dicho pago recurrente, así como de cancelar el servicio pasando el periodo que se señala en el párrafo anterior.

                Callmatik se reserva el derecho de modificar la mecánica de pago por Suscripción de conformidad con sus políticas y procesos.
              </Column>
              {/*
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                10.	PRUEBA GRATIS.

                Callmatik le ofrece que conozca nuestro producto de forma gratuita por lo que usted puede disfrutar de una prueba sin costo de nuestros servicios si se registra en los términos señalados en los presentes Términos y Condiciones.

                Nuestro Sitio le guiará a través de los pasos que deberá de tomar para obtener un periodo prueba de hasta un mes, el cual le permitirá a los servicios de la Plataforma Callmatik (“Prueba gratis”).

                Se le dirigirá a una pantalla emergente de aceptación reconociendo que ha obtenido una Prueba Gratis y que su periodo de inicio comienza de forma inmediata, para acceder a esta Prueba Gratis deberá proporcionar toda la información de registro y pago. El Usuario reconoce y acepta que se realice un cargo de $10.00 M.N. (diez pesos cero centavos, moneda nacional) para validar la información de pago.
                en su dispositivo de forma automatizada recordatorios regulares por correo electrónico para mejorar a una Suscripción pagada

                La prueba gratuita cubre el pack del servicio contratado, no las llamadas adicionales. Por ejemplo si esta contratando el servicio PRO, que incluye 30 llamadas, tendrá 30 llamadas gratuitas. Las llamadas adicionales a su plan se cobrarán de acuerdo al precio establecido para las llamadas adiciones. Aunque estén dentro del periodo de prueba gratis]

                Su Prueba Gratis finalizará después de 30 (treinta) días naturales y no podrá acceder a otro periodo de prueba.

                Si Callmatik descubre que el Usuario a través de engaño, información falsa, inexacta, perfiles falsos, o terceros que trasladen los servicios, ha utilizado más de un Periodo Gratis, realizará un cargo automático por todos y cada uno de los Periodos Gratis utilizados, incluyendo el primero.
              </Column>
              */}
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                10.	CAMBIOS EN LOS PRECIOS

                Callmatik se reserva el derecho de ajustar los precios de los planes de suscripción en cualquier momento según lo determine a su única y absoluta discreción. A menos que se estipule expresamente lo contrario en estos Términos y Condiciones, cualquier cambio en los precios de su servicio entrará en vigor cuando sea notificado por correo electrónico o a través de aviso en la Plataforma Callmatik.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                11.	RESTRICCIONES DE USO

                El Usuario reconoce, acepta y se obliga a no realizar cualquiera de las siguientes acciones en la Plataforma Callmatik:

                1)	No deberá acceder a los servicios para crear un servicio similar o competitivo;
                2)	Hacerse pasar por otra persona o entidad ni tergiversar su información con ninguna otra persona o entidad;
                3)	Intentar obtener acceso o información no autorizada de los servicios de Callmatik, la Plataforma Callmatik, o los Usuarios;
                4)	Usar la Plataforma Callmatik para cualquier propósito ilegal, en violación de cualquier ley;
                5)	Otorgar en sublicencia, revender, alquilar, prestar, asignar, ceder, donar o de otro modo transferir o distribuir el contenido de los servicios o sus derechos otorgados a el usuario en virtud de estos Términos;

                El Usuario entiende y acepta que cualquier violación a los presentes Términos las sanciones previstas lo harán responsable de resarcir los daños y perjuicios causados por sus actos y/u omisiones.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                12.	LÍMITE DE RESPONSABILIDAD

                El Usuario acepta y reconoce que en ningún caso Callmatik será responsable por daños, incluyendo sin limitar cualquier daño general, especial, incidental o consecuente, o daño por pérdida de beneficios, ingresos, datos, en que el Usuario o por parte de cualquier tercero, surjan del uso de la Plataforma Callmatik.

                La Plataforma Callmatik no será responsable del soporte técnico de servicios terceros contratados por el Usuario y que sean necesarios para la correcta prestación de los servicios. Si tiene alguna consulta relacionada con los requisitos requeridos para la contratación de los servicios, comuníquese a hola@callmatik.com

                1)	Callmatik no será responsable por cualquier reclamo o daño que el usuario pueda sufrir como resultado de su uso/uso indebido de cualquier Plataforma Callmatik, o de no acceder a los servicios por causas imputables al Usuario.

                2)	La información recibida por el Usuario no será responsabilidad de Callmatik, así como información incorrecta, incompleta o errónea que reciba a través de la Plataforma.

                3)	El Usuario reconoce y acepta que las exenciones de responsabilidad y las limitaciones de responsabilidad establecidas en estos Términos reflejan una asignación razonable y justa de riesgos entre el usuario y Callmatik, y que estas limitaciones son una base esencial para poner a la disposición los servicios de una manera económicamente factible.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                13.	RENUNCIA DE GARANTÍAS

                Callmatik no garantiza la disponibilidad y continuidad de la operación de la Plataforma Callmatik y/o los servicios, ni que estos cumplan con un objetivo u objeto en relación con alguna actividad específica del Usuario. El Usuario libera a Callmatik de cualquier responsabilidad por cualquier daño o perjuicio de cualquier naturaleza que considere que pueda surgir.

                Callmatik renuncia expresamente a todas las garantías de cualquier tipo, ya sean expresas o implícitas, incluyendo, pero sin limitarse a, las garantías implícitas de idoneidad respecto al contenido de la Plataforma Callmatik. En ese sentido Callmatik no otorga garantías respecto a que: (i) la Plataforma Callmatik estará disponible de forma ininterrumpida, oportuna, segura o libre de errores; y (ii) los resultados que se puedan obtener del uso de la Plataforma Callmatik serán exactos o confiables.

                En la medida en que lo permitan las leyes vigentes, todos los servicios y su contenido se proporcionan de la forma que el usuario puede validar al momento de contratción, sin declaraciones o garantías de ningún tipo, ya sean expresas o implícitas, con respecto a los mismos, y en particular, Callmatik no hace declaraciones ni garantías con respecto a la calidad del contenido de la Plataforma Callmatik o la adecuación del contenido de la Plataforma Callmatik para el propósito para el cual el usuario lo adquirió.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                14.	 CANCELACIÓN DEL SERVICIO

                Las Partes acuerdan que los servicios no tienen una vigencia más allá del periodo de contratación de la Suscripción, por lo que en caso de que usted desee cancelar el servicio deberá cancelarlo a través de la Plataforma Callmatik o dejar de pagar el servicio.

                El Usuario no no tendrá derecho a cualquier reembolso ya sea parcial o tal por la erogación de las cuotas de Suscripción pagadas a la fecha de cancelación.

                La cancelación del servicio deberá informarse al menos con 10 días de antelación.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                15.	Terminación del Servicio.

                Tenemos el derecho de terminar el servicio de forma inmediata sin responsabilidad para nosotros en los siguientes casos:

                1)	Usted o alguna persona por quien usted sea responsable ante la Ley incumple con los presentes Términos y Condiciones.
                2)	Incumple con el periodo de pago de la Suscripción.
                3)	El Usuario cae en insolvencia financiera.
                4)	Alguna declaración o solicitud de servicio que pueda proporcionarnos indicios de alguna actividad engañosa o fraudulenta.
                5)	El Usuario realice cualquier acto o Práctica Prohibida de conformidad con estos Términos y Condiciones o cualquier disposición legal aplicable.
                6)	Cuando medie instrucción de autoridad competente para realizar la suspensión inmediata de cualquier Servicio, con la finalidad de cesar la comisión de delitos.
                7)	Por fallecimiento del Cliente, en caso de personas físicas y por liquidación de la sociedad en caso de personas morales.
                8)	En el caso que el Usuario, persona moral, sea declarado en concurso mercantil.
                9)	Por la falsedad en la información o datos proporcionados por el Cliente, así como la no localización de éste derivado de dicha falta u omisión de datos

                La Terminación de los servicios no libera al Usuario de las responsabilidades y sanciones administrativas, civiles y penales que procedan, de conformidad con la legislación vigente.

                Queda entendido de que el ejercicio del derecho de terminación realizado por parte de Callmatik no constituye renuncia a cualquier acción legal que Callmatik decidiere ejercitar en contra del Usuario.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                16.	NOTIFICACIONES.

                El usuario podrá recibir comunicaciones correo electrónico o mensaje de texto de los representantes de Callmatik relacionados con los servicios. El usuario acepta y da su consentimiento para recibir dichas comunicaciones incluyendo información relacionada con los servicios.

                Cualquier aviso o notificación que haya de hacerse por parte de Callmatik respecto de los presentes Términos, deberá ser hecha a través de la Plataforma Callmatik en la sección de notificaciones y/o por medio de la siguiente dirección del correo electrónico hola@callmatik.com por lo que será considerado éste el medio oficial para brindarle comunicación directa y notificar de cualquier suceso.

                En caso de verse afectado por alguna falla de la Plataforma Callmatik, el Usuario podrá comunicarse a la siguiente dirección de correo electrónico hola@callmatik.com
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                17.	MODIFICACIONES.

                Callmatik puede, en cualquier momento, por cualquier motivo, a su exclusivo criterio, realizar cambios y/o modificar estos Términos. Si Callmatik realiza cambios y/o modificaciones que afecten al objeto de estos Términos, Callmatik enviará una notificación al correo electrónico proporcionado al momento de su registro que los Términos han sido actualizados. Callmatik también podrá proporcionarle formas adicionales de notificación de modificaciones y/o actualizaciones según corresponda. Si el Usuario no está de acuerdo con los cambios favor de abstenerse a usar los servicios. Sin embargo, para todos los cambios sustanciales en los Términos, Callmatik tomara las medidas razonables para notificarle dichos cambios si está inscrito en un Curso en el momento en que dichos cambios tengan vigencia.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                18.	AVISO DE PRIVACIDAD.

                Antes de proporcionar cualquier información y/o datos personales a Callmatik, el Usuario deberá leer y entender el aviso de privacidad, el cual Callmatik lo puso a disposición del Usuario.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                19.	CONFIDENCIALIDAD.

                El Usuario acepta y reconoce que toda la información contenida en la Plataforma Callmatik, así como aquella que Callmatik le proporcione, ya sea en forma escrita, electrónica o verbal, es información cuyo contenido se obliga a proteger dándole el carácter de confidencial (la “Información Confidencial”) de la cual podrá exclusivamente podrá imprimir y/o copiar cualquier información contenida o publicada en la Plataforma Callmatik estrictamente para uso personal, quedando prohibido el uso comercial. La Información Confidencial comprenderá, en manera enunciativa sin limitar, información electrónica, información contractual, información de terceros, información del desarrollo, actividades de negocio, productos, servicios de la Plataforma Callmatik y conocimientos técnicos, pasados, presentes y futuros de Callmatik.

                Callmatik protege la Información Confidencial proporcionada por el Usuario y es el responsable de su tratamiento cuando sea recabada a través de la Plataforma Callmatik.

                El Usuario se obliga expresa e irrevocablemente a mantener la Información Confidencial y no revelar su existencia así como el contenido, incluyendo de manera enunciativa más no limitativa de los presentes Términos, así como cualquier otro documento relacionado con Callmatik o la Plataforma Callmatik, por lo que el usuario deberá proporcionarla a las personas que necesiten conocerla bajo su exclusiva responsabilidad, excepto a consecuencia de alguna resolución de carácter judicial o por expresa orden de una autoridad administrativa.

                El Usuario se obliga a poner el cuidado necesario en la protección de la Información Confidencial, acepta y reconoce que dentro de la Información Confidencial que le será entregada por Callmatik, podrán existir secretos industriales, los cuales se encuentran protegidos en términos del artículo 163 de la Ley Federal de Protección a la Propiedad Industrial, por tal motivo la información industrial y comercial es propiedad de Callmatik y le permite obtener y mantener, entre otras cosas, ventajas económicas y de mercado frente a sus competidores. El Usuario está de acuerdo y acepta conservar la información proporcionada por Callmatik en estricta confidencialidad, y en este acto se obliga a no vender, divulgar, transferir, modificar, traducir, reproducir ni poner de otra forma Información Confidencial a disposición de terceros.

                El Usuario acepta y reconoce que al proporcionar la información de carácter personal requerida en alguno de los servicios que se prestan en la Plataforma Callmatik, otorgan a Callmatik la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor.

                El Usuario entiende y acepta que las obligaciones de confidencialidad estarán vigentes durante todo el tiempo que la Información Confidencial se le de ese carácter. Toda divulgación no autorizada de la Información Confidencial de Callmatik constituye una violación a la legislación aplicable e incluso puede constituir la comisión de un delito, por lo que el Usuario deberá indemnizar a Callmatik de cualquier pérdida, daño, perjuicio, cargo o gastos, incluyendo honorarios de abogados.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                20.	DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL

                Uso de los materiales de propiedad de Callmatik. La Plataforma Callmatik contiene materiales con derechos de autor, marcas comerciales, información propietaria y confidencial, y propiedad intelectual de Callmatik y de los licenciatarios de Callmatik (colectivamente, "Materiales de propiedad"), incluyendo, sin limitación, el código fuente, el vídeo, el texto, el software, las fotos, los gráficos, las imágenes, la música y el sonido. El Usuario se compromete a no modificar, publicar, transmitir, participar en la transferencia o venta de, crear trabajos derivados de, o de cualquier manera explotar, en su totalidad o en parte, cualquier material de propiedad. Sólo se puede acceder a los Materiales de Propiedad Intelectual a través de la Plataforma Callmatik, y no por o desde ningún otro sitio o medio.

                Callmatik le concede una licencia no exclusiva para el uso y la instalación de los Materiales Descargables Autorizados, sujeta a todos los términos y condiciones establecidos en las Condiciones. Esta licencia rige todas y cada una de las actualizaciones de software o características adicionales proporcionadas por Callmatik que reemplacen o complementen la versión original instalada de los Materiales Descargables Autorizados, a menos que esas otras actualizaciones o características estén cubiertas por una licencia separada, en cuyo caso rigen esos términos.

                Callmatik se reserva todos los derechos de propiedad intelectual de los Materiales de propiedad, salvo los que se conceden específicamente en virtud de la licencia aplicable otorgada al usuario en virtud de estas Condiciones. No se permite el envío, la copia, la transmisión, la retransmisión, la distribución, la redistribución, la publicación, la reedición, la des-compilación, la ingeniería inversa o la reproducción, el almacenamiento, la transmisión, la modificación o la explotación comercial de cualquier Material de Propiedad en cualquier forma o por cualquier medio, para cualquier propósito, sin nuestro permiso expreso por escrito.

                La Plataforma Callmatik está protegida por derechos de autor. El cumplimiento de todas las leyes de derechos de autor aplicables es responsabilidad del Usuario.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                21.	INDEMNIZACIÓN.

                El Usuario está de acuerdo en indemnizar a Callmatik, a sus afiliados, proveedores, vendedores y asesores por cualquier acción, demanda o reclamación, incluso de honorarios de abogados y de costas judiciales derivadas de cualquier incumplimiento por parte del Usuario a los presentes Términos; incluyendo, sin limitación de alguna de las derivadas de cualquier aspecto relativo al uso de la Plataforma Callmatik, ya sea información contenida o disponible en o a través de la Plataforma Callmatik o de injurias, difamación o cualquier otra conducta violatoria de los presentes Términos por parte del Usuario en el uso de la Plataforma Callmatik señalado con anterioridad. La violación a las leyes aplicables o tratados internacionales relativos a los derechos de propiedad intelectual, contenidos o disponibles en, o a través de la Plataforma Callmatik.

                Sujeto a cualquier ley vigente, el usuario acepta mantener a Callmatik indemne y eximida de responsabilidad con respecto a cualquier reclamación que un tercero pudiera presentar en contra de Callmatik que se relacione o derive de estos Términos, a raíz de su inscripción y/o participación en un Curso. Esta exención incluye reclamos que deriven del uso por parte de la plataforma de una manera que no cumpla con estos Términos o el acceso no autorizado o prohibido por un tercero a su perfil. Esta exención también incluye toda responsabilidad o pérdida que Callmatik pudiera sufrir como resultado de una reclamación, incluidos los costos legales en la escala más alta permitida y cualquier costo legal y de cobranza adicional.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                22.	NO RENUNCIA DE DERECHOS.

                La inactividad por parte de Callmatik, al no ejercicio de alguna acción o derecho que por ley, o en virtud de estos Términos tenga derecho a emplear Callmatik en ningún momento deberá de entenderse como un acto constitutivo de renuncia, o como una renuncia como tal respecto de dicho derecho o acción.

                Si Callmatik decide no hacer cumplir alguna parte de estos Términos, esto no significa que no pueda hacerlo más adelante. Ninguna renuncia a reclamar algo en función de algún término del presente se considerará como una renuncia adicional o continua de dicho término.
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                23.	JURISDICCIÓN Y LEY APLICABLE.

                Para todo lo relativo a la interpretación, cumplimiento y/o ejecución de estos Términos y cualesquiera obligaciones extracontractuales derivadas de, se regirán e interpretarán de conformidad con las leyes de México sin dar efecto a sus disposiciones sobre conflicto de leyes. Todas las disputas derivadas de los presentes Términos se someterán a la exclusiva jurisdicción de los juzgados y tribunales Ciudad de México o a la jurisdicción de los juzgados y tribunales de su país de residencia, si así lo estableciera la ley aplicable.

              </Column>
            </Columns>
          </Column>
        </Columns>
      </Container>
    </ContTYC >
  )
}
