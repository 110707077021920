import styled, { createGlobalStyle } from 'styled-components'
import { GlobalBackgroundColors, GlobalColors, GlobalFonts } from '../../styles/GlobalStyles';

export const ContTYC = styled.div`
@media(min-width: 300px) and (max-width: 767px){
    margin-top: 132px;
    margin-bottom: 40px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 132px;
    margin-bottom: 60px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 80px;
    margin-bottom: 100px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 70px;
    margin-bottom: 100px;
  }
  @media(min-width: 1920px){
    margin-top: 100px;
    margin-bottom: 100px;
  }
`